import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input } from '@angular/core';
import { coerceBoolean } from '@util/functions/objects';
import { UtilCustomCSSPropertyService } from '@util/services/util-custom-css-property.service';
import { KurzIconType } from '../../../custom/custom-configuration-modules/custom-icon-types-mapping.module';
import { KurzIconSize } from '../icon/icon.component';
import { ColorThemableComponent } from '../shared/color-theme/theme.enum';
import { BehaviorSubject } from 'rxjs';

export enum KurzIconButtonType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  BASE = 'BASE',
  DEFAULT = 'DEFAULT',
}

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconButtonComponent implements ColorThemableComponent {

  private _iconClasses: string;
  private _iconSizeClass: string;
  private _size: KurzIconSize = KurzIconSize.MEDIUM;


  @HostBinding('class')
  private get hostClasses(): string[] {
    const arr: string[] = [
      'app-icon-button',
      (this._iconSizeClass || 'app-icon-button-medium'),
    ];

    if (this.withBorder) {
      arr.push('app-icon-button-with-border');
    }

    if (this.disabled) {
      arr.push('app-icon-button-disabled');
    }

    return arr;
  }

  constructor(private readonly elementRef: ElementRef, private readonly utilCustomCSSPropertyService: UtilCustomCSSPropertyService) {}

  @Input()
  iconType: KurzIconType;

  get iconClasses(): string {
    return this._iconClasses;
  }

  @Input()
  set size(value: KurzIconSize) {
    this._size = value;
    this._iconSizeClass = 'app-icon-button-medium';
    switch(value) {
      case KurzIconSize.EXTRA_SMALL: this._iconSizeClass = 'app-icon-button-extra-small'; break;
      case KurzIconSize.SMALL: this._iconSizeClass = 'app-icon-button-small'; break;
      case KurzIconSize.LARGE: this._iconSizeClass = 'app-icon-button-large'; break;
      case KurzIconSize.EXTRA_LARGE: this._iconSizeClass = 'app-icon-button-extra-large'; break;
    }
  }

  get size(): KurzIconSize {
    return this._size;
  }

  @Input()
  tabindex: string = '0';

  @Input()
  withBorder: boolean;

  @HostBinding('class.disabled')
  @Input()
  disabled: boolean;

  @Input()
  superscript: string;

  @Input()
  set circle(value: boolean) {
    const real = coerceBoolean(value);
    if (real) {
      this.utilCustomCSSPropertyService.setValue('--icon-button-border-radius', '50%', this.elementRef?.nativeElement);
    } else {
      this.utilCustomCSSPropertyService.setValue('--icon-button-border-radius', '0%', this.elementRef?.nativeElement);
    }

  }

  buttonClasses$ = new BehaviorSubject<string[]>([]);

  @Input()
  set buttonClasses(value: string[]) {

    if (typeof value === 'string') {
      value = (value as string).split(' ').filter(cl => !!cl);
    }

    this.buttonClasses$.next(value);
  }

}
